import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { SignInFormComponent } from './sign-in-form/sign-in-form.component';
import { PasswordResetControlsComponent } from './password-reset-controls/password-reset-controls.component';
import { PatternLibraryModule } from '@pattern-library/pattern-library.module';
import { ChangePasswordDialogComponent } from '@auth-n/change-password/change-password-dialog.component';
import { DesignSystemModule } from '@design/design-system.module';
import { LegacyFormsModule } from '@pattern-library/legacy-forms/legacy-forms.module';
import { DesignSystemFormsModule } from '@design/forms/design-system-forms.module';
import { PasswordInputComponent } from '@auth-n/password-input/password-input.component';
import { ResetPasswordFormComponent } from '@auth-n/reset-password/reset-password-form.component';
import { SignInFlowComponent } from './sign-in-flow/sign-in-flow.component';

const declarations = [
  SignInFormComponent,
  ResetPasswordFormComponent,
  PasswordResetControlsComponent,
  ChangePasswordDialogComponent,
  SignInFlowComponent
];

/**
 * Authentication module containing services and ui-components for managing the user's authenticated models.
 * Runtime apps should pass in their AWS Cognito settings using the forRoot static via their Core module.
 */
@NgModule({
  declarations: [...declarations, PasswordInputComponent],
  imports: [RouterModule, PatternLibraryModule, LegacyFormsModule, DesignSystemModule, DesignSystemFormsModule],
  exports: [...declarations]
})
export class AuthenticationComponentsModule {}
